import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { connect, disconnect } from '../../redux/blockchain/blockchainActions';
import logo from '../../imgs/logo.png';
import style from '../../css/sharetoearn.css'
import axios from "axios";
const endpoint = process.env.REACT_APP_API_END_POINT;
const api_key = process.env.REACT_APP_API_KEY;

export default function Header(props) {
  const navigate = useNavigate()
  const [disconnectBtn , setDisConnectBtn] = useState("")
  const page = props.page

  const checkToken = () => {
    let login_token = localStorage.getItem('login_token')
    if(login_token){
      axios.post(`${endpoint}/api/v1/check-token`, {login_token: login_token}, {headers:{"X-API-KEY": api_key , "Content-Type": "application/json"}})
      .then(response => {
        if(props.page == 'login'){
          navigate('/share-to-earn')
        }else{
          setDisConnectBtn(<button type='button' onClick={disconnect} className='header-menu disconnect-btn'><span>Disconnect</span> </button>)
        }
      }).catch(error => {
        localStorage.removeItem('login_token')
        navigate('/share-to-earn/login')
      });
    }else{
      navigate('/share-to-earn/login')
    }
  }
  
  const disconnect = () => {
    localStorage.removeItem('login_token')
    navigate('/share-to-earn/login')
  }

  useEffect(() => {
    checkToken()
  }, []);
  
    return (
     
      <div className="container">
        <header>
          <div className="form-inline">
            <div className="header-logo">
              <Link to='/share-to-earn'><img src={logo} alt="" />( SHARE TO EARN )</Link>
            </div>
            <div className="header-items">
                <Link to="/share-to-earn" className={`${page == 'dashboard' ? 'active' : ''} header-menu`}>News</Link>
                <Link to="/share-to-earn/history" className={`${page == 'history' ? 'active' : ''} header-menu`}>History</Link>
                {/* <Link to="/share-to-earn/withdraw" className={`${page == 'withdraw' ? 'active' : ''} header-menu`}>Withdraw</Link> */}
                <Link to="/share-to-earn/transactions" className={`${page == 'transactions' ? 'active' : ''} header-menu`}>Wallet</Link>
                <Link to="/share-to-earn/profile" className={`${page == 'profile' ? 'active' : ''} header-menu`}>Profile</Link>
                {disconnectBtn}
            </div>
          </div>
        </header>
      </div>
    )
  }
    