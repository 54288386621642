const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  return async (dispatch, getState) => {
    dispatch(fetchDataRequest());
    try {
      const smartContract = getState().blockchain.smartContract;
      let totalSupply = await smartContract.methods.totalSupply().call();

      dispatch(fetchDataSuccess({
        totalSupply,
      }));
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

