import React, { useState , useEffect , Fragment } from 'react';
import copy from 'clipboard-copy';
import Header from '../Header';
import Footer from '../Footer';
import uniswap_icon from '../imgs/uniswap-icon.png';
//import etherscan_icon from '../imgs/etherscan-icon.png';
import op_icon from '../imgs/op-icon.png';
import banner from '../imgs/banner.png';
import blocksky_icon from '../imgs/blocksky-icon.png';
import blocksky_doc from '../imgs/blocksky-doc.png';
import ano_icon from '../imgs/ano-icon.png';
import investment_obj from '../imgs/investment-object.png';
import copy_icon from '../imgs/copy-icon.png';

const Token = () => {

  const [data, setData] = useState([
    { id: 1, token_address: '0x5cCb63db1AF44E93095AF45aA820a2109DFecfE6' },
  ]);

  const handleCopyClick = (rowData) => {
    const dataToCopy = `${rowData.token_address}`;
    copy(dataToCopy);
    alert('Token Address add to Clipboard!');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  return (
    <div>
      <div className="bg-main1 animated wow fadeIn">
        <Header page="token" />
        <div className="banner">
          <div className="container position-relative">
            <div className="banner-text wow slideInLeft">
              <h1>Blocksky Token</h1>
              <h6>Investment tokens that pay dividends.</h6>
              <div className="banner-buttons">
                <a href="https://app.uniswap.org/swap" target="_blank" rel="noopener noreferrer"><img src={uniswap_icon} /> Uniswap</a>
                <a href="https://optimistic.etherscan.io/token/0x5cCb63db1AF44E93095AF45aA820a2109DFecfE6" target="_blank" rel="noopener noreferrer"><img src={op_icon} /> OPTIMISM</a>
              </div>
            </div>
          </div>
          <img src={banner} alt="" className="w-100" />
          <div className="mobile-banner" />
        </div>
        <div className="token-wrapper">
          <h6 className="text-center">Token Address</h6>
          {data.map((row) => (
            <div className="token-form wow fadeIn" data-wow-duration="1.2s">
              <div className="token">
                <tr key={row.id}>
                  <td>{row.token_address}</td>
                </tr>
              </div>
              <button type="button" className="copy-token-btn" onClick={() => handleCopyClick(row)}><img src={copy_icon} alt="" /> COPY</button>
            </div>
          ))}
        </div>
        <div className="blocksky">
          <div className="container">
            <h1 className="text-center text-fade wow fadeIn">Blocksky Token (BLOCKSKY)</h1>
            <div className="blocksky-wrapper">
              <div className="blocksky-item wow slideInDown"  data-wow-duration="0.7s">
                <div>
                  <img src="https://www.blocksky.io/assets/img/logo.png?v=1" className="icon" />
                  <h5>Whitepaper</h5>
                  <h6>A document explaining Blocksky’s projects.</h6>
                </div>
              </div>
              <div className="blocksky-item wow slideInUp"  data-wow-duration="0.7s">
                <div>
                  <img src={blocksky_icon} className="icon bc-icon" />
                  <h5>Track Token Price</h5>
                  <h6>Track Blocksky Token price and other information relating to the token.</h6>
                </div>
              </div>
              <div className="blocksky-item wow slideInDown"  data-wow-duration="0.7s">
                <div>
                  <img src={blocksky_doc} className="icon bc-doc" />
                  <h5>Blocksky Token’s <br />source code.</h5>
                  <h6>Blocksky Source Code.</h6>
                </div>
              </div>
              <div className="blocksky-item wow slideInUp"  data-wow-duration="0.7s">
                <a href="https://sourcehat.com/audits/BlockskyToken/" target='_blank'>
                  <div>
                    <img src={ano_icon} className="icon  bc-icon" />
                    <h5>Token Audit</h5>
                    <h6>Audited by Sourcehat.</h6>
                  </div>
                </a>
              </div>
            </div>
            <div className="blocksky-state wow slideInUp"  data-wow-duration="0.7s">
              <span>Token Utility</span>
              <span>Staking</span>
              <span>Stake Blocksky<br />Token to earn yields.</span>
            </div>
          </div>
        </div>
        <div className="investment">
          <div className="container">
            <h5 className="wow slideInDown"  data-wow-duration="0.7s">Blocksky Token stakers can receive dividends<br /> based on net profit generate from blocksky.io<br /> and other businesses owned by Blocksky.</h5>
            <Fragment>
              <img src={investment_obj} className="investment-object wow fadeIn" />
            </Fragment>
          </div>
          <Fragment>
              <img src={investment_obj} className="investment-object-mobile wow fadeIn" />
            </Fragment>
        </div>
      </div>
      <Footer class="pt-3" />
    </div>
  )
};


export default Token;
