import React from "react";
import stak_thumbs from '../imgs/stake-thumbs.webp';

const Staking = () => {
    return(
        <section className="sec-staking animated wow fadeIn">
        <div className="container">
          <div className="box-stake">
            <div className="stake-items">
              <h3 className="text-gradient text-h3">Staking Pool</h3>
              <p className="stake-detail">Earn dividends from staking pool.</p>
              <div className="stake-button">
                <a href="https://www.blocksky.io/assets/img/Blocksky_Whitepaper_31.07.24.pdf" className="btn-bsky-style sm staking-more" target="_blank">Learn more</a>
              </div>
            </div>
            <div className="stake-items thumbs">
              <img src={stak_thumbs} alt="" />
            </div>
          </div>
        </div>
      </section>
    )
};

export default Staking;
