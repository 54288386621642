import React from "react";
import bsky_network from '../imgs/bsky-network.png';
import sh_thumbs from '../imgs/sh-thumbs.webp';

const Shareto = () => {
    return(
        <section className="sec-shareto animated wow fadeIn">
        <div className="container">
          <div className="box-shareto">
            <div className="shareto-items">
              <div className="d-flex">
                <img src={bsky_network} className="icon-head" alt="Blocksky" /> 
                <h2 className="text-gradient  share-to-earn-text"> Share-to-Earn &<br></br> Play-to-Earn</h2> 
              </div>
              <p className="sh-detail">
                Introducing share-to-earn revenue model.
                <br />If you hold an NFT, you will receive a share ID that give you access to share-to-earn feature.
              </p>
              <div>
                <a href="https://www.blocksky.io/assets/img/Blocksky_Whitepaper_31.07.24.pdf" className="btn-bsky-style sm" target="_blank">Learn more</a>
              </div>
            </div>    
            <div className="shareto-items sh-thumbs animate wow fadeInRight">
              <img src={sh_thumbs} alt="" />
            </div>
          </div>
        </div>
      </section>
    )
};

export default Shareto;
