import React from "react";
import gl1_thumbs from '../imgs/gl1-thumbs.webp?v=2';
import { NavLink, Link } from 'react-router-dom';

const Gallery = () => {
    return(
        <section className="sec-gallery">
        <div className="container">
          <div className="box-gallery">
            <div className="gallery-left animated wow bounceInLeft">
              <img src={gl1_thumbs} alt="" />
            </div>
            <div className="gallery-right">
              <h2 className="text-gradient text-h1">Blocksky NFT Gallery.</h2>
              <div className="nftg-button mt-3">
                <nav>
                <NavLink to='https://opensea.io/BlockskyNFT' target="_blank" className="btn-bsky-style">Explore</NavLink>
                <Link to="/mint" className="btn-bsky-style">Mint</Link>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
};

export default Gallery;
