import React from "react";
import t_roadmap from '../imgs/t-roadmap.png';
import i_check from '../imgs/i-check.png';

const Roadmap = () => {
    return(
        <section className="sec-roadmap">
        <div className="bg-roadmap">&nbsp;</div>
        <div className="container sm">
          <div className="box-roadmap">
            <div className="roadmap-left">
              <h3 className="text-gradient text-h1-big">ROADMAP</h3>
              <div className="roadmap-thumbs"><img src={t_roadmap} alt="" /></div>
            </div>
            <div className="roadmap-right">
              <div className="roadmap-rows animated wow fadeIn">
                <div className="roadmap-icon first"><img src={i_check} alt="" /></div>
                <div className="roadmap-head text-gradient2">Q4 2022</div>
                <p className="roadmap-text first fin">
                  - Register domain name for the website.<br />
                  - Design cryptoasset price-tracking and crypto news website.<br />
                  - Develop backend and frontend for the website.<br />
                  - Launch blocksky.io in October.<br />
                  - Launch Twitter, Facebook, and Telegram.
                </p>
              </div>
              <div className="roadmap-rows animated wow fadeIn">
                <div className="roadmap-icon first"><img src={i_check} alt="" /></div>
                <div className="roadmap-head text-gradient2">Q1 2023</div>
                <p className="roadmap-text fin">
                  - Release the whitepaper.<br />
                  - Create and develop Blocksky tokens.<br />
                  - Develop share-to-earn.<br />
                  - Develop and design Blocksky NFT collection.
                </p>
              </div>
              <div className="roadmap-rows animated wow fadeIn">
                <div className="roadmap-icon first"><img src={i_check} alt="" /></div>
                <div className="roadmap-head text-gradient2">Q2 2023</div>
                <p className="roadmap-text fin">
                  - Launch token presale on pinksale.finance throughout Q2.<br/>
                  - Launch Blocksky NFT collection and start minting the NFTs <br />
                  &nbsp;&nbsp;&nbsp;on the website.<br />
                  - Launches NFT collection on Opensea.<br />
                </p>
              </div>
              <div className="roadmap-rows animated wow fadeIn">
                <div className="roadmap-icon first"><img src={i_check} alt="" /></div>
                <div className="roadmap-head text-gradient2">Q3 2023</div>
                <p className="roadmap-text fin">
                  - Launches Blocksky tokens on Uniswap Initial DEX Offering (IDO).
                </p>
              </div>
              <div className="roadmap-rows animated wow fadeIn">
                <div className="roadmap-icon first"><img src={i_check} alt="" /></div>
                <div className="roadmap-head text-gradient2">Q4 2023</div>
                <p className="roadmap-text fin">
                  - Start share-to-earn <br />
                  &nbsp;&nbsp;&nbsp;(share-to-earn money will be paid every other month).<br />
                  - Pay money for share-to-earn.
                </p>
              </div>
              <div className="roadmap-rows animated wow fadeIn">
                <div className="roadmap-icon first"><img src={i_check} alt="" /></div>
                <div className="roadmap-head text-gradient2">Q1 2024</div>
                <p className="roadmap-text fin">
                - Launch Blocksky NFT collection on the website and on &nbsp;&nbsp;&nbsp;OpenSea.<br/>
                - Launch and promote share-to-earn.
                </p>
              </div>
              <div className="roadmap-rows animated wow fadeIn">
                <div className="roadmap-icon first"><img src={i_check} alt="" /></div>
                <div className="roadmap-head text-gradient2">Q2 2024</div>
                <p className="roadmap-text fin">
                  - Pay compensation for share-to-earn.<br/>
                  - Promote Blocksky Token
                </p>
              </div>
              <div className="roadmap-rows last animated wow fadeIn">
                <div className="roadmap-icon first"><img src={i_check} alt="" /></div>
                <div className="roadmap-head text-gradient2">Q4 2024</div>
                <p className="roadmap-text">
                  - Launch Blocksky Token staking.<br/>
                  - Launch Blocksky NFT marketplace
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
};

export default Roadmap;
