import React from "react";
import bg_tokenview from '../imgs/bg-tokenview.webp';
import token_github from '../imgs/token-github.png';
import token_blocksky from '../imgs/token-blocksky.png';
import token_optimism from '../imgs/token-optimism.png';
import token_ether from '../imgs/token-ether.png';
import token_erc20 from '../imgs/token-erc20.png';
import token_staking from '../imgs/token-staking.png';
import { NavLink } from 'react-router-dom';


const Tokenview = () => {
    return(
        <div className="container sm">
        <section className="sec-tokenview animated wow fadeIn">
          <h3 className="text-gradient text-h1 text-center">BLOCKSKY<br />TOKEN</h3>
          <div className="tokenview-thumbs">
            <img src={bg_tokenview} alt="" />
            <a href="https://www.blocksky.io/">
              <span className="token-blocksky"><img src={token_blocksky} alt="" /></span>
            </a>
            <a href="https://etherscan.io/token/0x5cCb63db1AF44E93095AF45aA820a2109DFecfE6#code" target="_blank">
              <span className="token-ether"><img src={token_optimism} alt="" /></span>
            </a>
            <a href="https://etherscan.io/token/0x5cCb63db1AF44E93095AF45aA820a2109DFecfE6" target="_blank">
              <span className="token-erc20"><img src={token_erc20} alt="" /></span>
            </a>
            <NavLink to='/soon'>
              <span className="token-staking"><img src={token_staking} alt="" /></span>
              </NavLink>
            <span className="token-github">
              <img src={token_github} alt="" />
              <span className="text">
                <a href="/soon" target="_blank">Documentation</a>
                <br />
                <NavLink to='/soon'>GitHub</NavLink>
              </span>
            </span>
          </div>
        </section>
      </div>
    )
};

export default Tokenview;
