import React, { useState, useRef , useEffect, createRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "./Header";
import logo from '../../imgs/logo.png';
import Ethereum_logo from '../../imgs/Ethereum_logo.svg';
import nftLogo from '../../imgs/nft-logo.png';
import nftLogoBlack from '../../imgs/nft-black.png';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from "react-bootstrap";
import moment from 'moment-timezone';
// import Toast from './components/Toast';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const endpoint = process.env.REACT_APP_API_END_POINT;
const api_key = process.env.REACT_APP_API_KEY;

export default function Withdraw(){
    const navigate = useNavigate()
    const input = createRef()
    const [balance, setBalance] = useState(0.00);
    const [usdPrice, setUsdPrice] = useState(0.00);
    const [account , setAccount] = useState([])
    const [alertBg , setAlertBg] = useState('success')
    const [alertColor , setAlertColor] = useState('text-white')
    const [alertMsg , setAlertMsg] = useState('')
    const [alertIcon , setAlertIcon] = useState(<i className='fas fas-check-circle'></i>)
    const [disabled , setDisabled] = useState(false)

    const getAccount = () => {
        let account = localStorage.getItem('userAccount')
        if(account){
            if (account.length <= 5 + 5) {
                return account;
            }
            setAccount(account.substring(0, 5) + '...' + account.substring(account.length - 5))
        }
    }

    useEffect(() => {
        getAccount()
        getUser()
    }, [])

    const getUser = () => {
        const login_token = localStorage.getItem('login_token')
        if(login_token){
            axios.post(`${endpoint}/api/v1/user`, {login_token: login_token}, {headers:{"X-API-KEY": api_key , "Content-Type": "application/json"}})
            .then(response => {
                let user = response.data.user
                let balance = user.balance
                let priceUsd = balance * user.priceUsd

                const formattedBalance = balance.toLocaleString('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });

                priceUsd = priceUsd.toLocaleString('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
                setUsdPrice(priceUsd)
                setBalance(formattedBalance)
            })
            .catch(error => {
                console.error("Error", error);
            });
        }
    }

    const [showToast, setShowToast] = useState(false);

    const alertToast = (msg , type , reload = false) => {
        setAlertBg(type)
        setAlertMsg(msg)
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
            if(reload){
                window.location.reload()
            }
        }, 5000);
    };

    const Withdraw = () => {
        setDisabled(true)
        const amount = input.current.value
        if(amount < 1){
            setDisabled(false)
            alertToast('Please enter amount.', 'warning')
            return
        }
        let number = balance;
        let floatNumber = parseFloat(number.replace(",", ""));
        if(amount > floatNumber){
            setDisabled(false)
            alertToast('Blocksky tokens are not enough for withdrawal.', 'warning')
            return
        }
        alertToast('Processing..' , 'primary')

        const login_token = localStorage.getItem('login_token')
        axios.post(`${endpoint}/api/v1/transaction/create`, {login_token: login_token , amount:amount} , {headers:{"X-API-KEY": api_key , "Content-Type": "application/json"}})
        .then(response => {
            setDisabled(false)
            alertToast('Blocksky Token withdrawal successful.' , 'success' , true)
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        })
        .catch(error => {
            setDisabled(false)
            if(error.response.data){
                alertToast(error.response.data.error , 'danger')
            }else{
                alertToast(error.message , 'danger')
            }
        })
    }

    const maxBlocksky = () => {
        let number = balance;
        let floatNumber = parseFloat(number.replace(",", ""));
        input.current.value = floatNumber
    }

    const toggleShowA = () => setShowToast(!showToast);
    return (
        <div className="share-body">
            <Header page="withdraw"></Header>
            <div className="container mt-4">
                <div className="row justify-content-center">
                    <div className="col-md-7 col-xxl-4">
                        <div className="card-balance">
                            <h4>Balance <small>({account})</small></h4>
                            <h3 className='balance-text'>{balance} <small>BLOCKSKY</small></h3>
                            <span>≈ ${usdPrice}</span>
                            <hr />
                            <div className="input-group mb-3">
                                <button type="button" onClick={maxBlocksky} className="input-group-text max-btn">Max</button>
                                <input type="number" className="form-control" ref={input} defaultValue={0} />
                                <span className="input-group-text blocksky-coin-text">Blocksky</span>
                            </div>
                            <button type='button' onClick={Withdraw} disabled={disabled} className='confirm-withdraw-btn'>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
                    <Toast show={showToast} bg={alertBg} onClose={toggleShowA}>
                        <Toast.Header>
                            <i className="fas fa-bell me-2"></i>
                            <strong className="me-auto">Alert</strong>
                        </Toast.Header>
                        <Toast.Body className={`${alertColor}`}>{alertMsg}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>   
        </div>
    )
}
