import ProjectPage from './pages/Project';
import MintPage from './pages/Mint';
import NotFound from './pages/NotFound';
import Coming from './pages/Coming';
import Soon from './pages/Soon';
import Token from './pages/Token';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import ShareToEarn from './pages/sharetoearn/Dashboard';
import ShareToEarnLogin from './pages/sharetoearn/Login';
import ShareToEarnAdminLogin from './pages/sharetoearn/AdminLogin';
import ShareToEarnHistory from './pages/sharetoearn/History';
import Profile from './pages/sharetoearn/Profile';
import Withdraw from './pages/sharetoearn/Withdraw';
import Transactions from './pages/sharetoearn/Transactions';


function App() {
  return (
    <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProjectPage />} />
        <Route path="/mint" element={<MintPage />} />
        <Route path="/soon" element={<Soon />} />
        <Route path="/token" element={<Token />} />
        <Route path="/farming" element={<Coming />} />
        <Route path="/share-to-earn" element={<ShareToEarn />} />
        <Route path="/share-to-earn/login" element={<ShareToEarnLogin />} />
        <Route path="/share-to-earn/admin-login" element={<ShareToEarnAdminLogin />} />
        <Route path="/share-to-earn/history" element={<ShareToEarnHistory />} />
        <Route path="/share-to-earn/profile" element={<Profile />} />
        <Route path="/share-to-earn/transactions" element={<Transactions />} />
        <Route path="/share-to-earn/withdraw" element={<Withdraw />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
    </Provider>
  );
}

export default App;
