import React, { useState, useRef, useEffect, createRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";
// import Toast from './components/Toast';
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import nftLogoBlack from "../../../imgs/nft-black.png";

const endpoint = process.env.REACT_APP_API_END_POINT;
const api_key = process.env.REACT_APP_API_KEY;

export default function Wallet(props) {

    const input = createRef();
    const [balance, setBalance] = useState(0.0);
    const [affiliateBalance, setAffiliateBalance] = useState(0.0);
    const [balanceUsdPrice, setBalanceUsdPrice] = useState(0.0);
    const [usdPriceAff, setUsdPriceAff] = useState(0.0);
    const [alertBg, setAlertBg] = useState("success");
    const [alertColor, setAlertColor] = useState("text-white");
    const [alertMsg, setAlertMsg] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [account, setAccount] = useState([]);
    const [totalFriends , setTotalFriends] = useState(0)
    const [friends , setFriends] = useState([])
    const [nftImage , setNftImage] = useState(nftLogoBlack)
    const [feeBlocksky , setFeeBlocksky] = useState(0)
    const [minBlocksky , setMinBlocksky] = useState(0)
    const [withdrawAlert , setWithdrawAlert] = useState("")
    const [withdrawAlertStatus , setWithdrawAlertStatus] = useState("")

    useEffect(() => {
        getUser()
    }, []);


    const getUser = () => {
        let user = props.user;
        let priceUsd = props.priceUsd;
        if(user){
            const crypto_address = user.crypto_address
            setAccount(
                crypto_address.substring(0, 5) + "..." + crypto_address.substring(crypto_address.length - 5)
            );

            let balance = user.balance;
            const friends = user.users
            setTotalFriends(friends.length)
            if (user.affiliate_balance) {
                let affiliateBalance = user.affiliate_balance
                affiliateBalance = affiliateBalance.toLocaleString("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
                setAffiliateBalance(affiliateBalance);

                let priceUsdAff = priceUsd * user.affiliate_balance;
                priceUsdAff = priceUsdAff.toLocaleString("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
                setUsdPriceAff(priceUsdAff);
            }

            let fee = 5 / priceUsd;
            setFeeBlocksky(fee.toFixed(2))

            let minBlocksky = 50 / priceUsd;
            setMinBlocksky(minBlocksky.toFixed(2))

            const formattedBalance = balance.toLocaleString("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            let formatBalanceUsd = balance * priceUsd
            formatBalanceUsd = formatBalanceUsd.toLocaleString("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            setBalanceUsdPrice(formatBalanceUsd);
            setBalance(formattedBalance);
        }
    };

    const getFriends = () => {
        const login_token = localStorage.getItem("login_token");
        if (login_token) {
          axios
            .post(`${endpoint}/api/v1/user/friends`, { login_token: login_token }, {headers:{"X-API-KEY": api_key}})
            .then((response) => {
               setFriends(response.data.friends)
               setShowFriend(true)
            })
            .catch((error) => {
              console.error("Error", error);
            });
        }
    };

    const friendElements = friends.map((friend , index) => {
        let nftFriendImage = nftImage
        let friendAccount = friend.crypto_address    
        let nftName = "No NFT"    
        if(friend.total_nft){
            if(friend.total_nft > 0){
                nftFriendImage = `${endpoint}/api/v1/nft/${friend.nft[0]}`
            }
            if(friend.nft_name){
                nftName = friend.nft_name
            }
        }
        return <li>
                <div className="friend-profile" style={{backgroundImage: "url('"+nftFriendImage+"')"}}>
                </div>
                <div className="friend-account">
                    <small>{friendAccount.substring(0, 5) + "..." + friendAccount.substring(friendAccount.length - 5)}</small><br></br>
                    <small>({nftName})</small>
                </div>
            </li>
    })

    const maxBlocksky = () => {
        let number = balance;
        let floatNumber = parseFloat(number.replace(",", ""));
        input.current.value = floatNumber
    }

    const Withdraw = () => {
        setDisabled(true)
        const amount = input.current.value
        if(amount < 1){
            setDisabled(false)
            setWithdrawAlertStatus("warning")
            setWithdrawAlert(`Please enter amount.`)
            return
        }
        let number = balance;
        let floatNumber = parseFloat(number.replace(",", ""));
        if(amount > floatNumber){
            setDisabled(false)
            setWithdrawAlertStatus("warning")
            setWithdrawAlert(`Blocksky tokens are not enough for withdrawal.`)
            return
        }
        console.log(amount < parseFloat(minBlocksky) ? 'true' : 'false')
        if(amount < parseFloat(minBlocksky)){
            setDisabled(false)
            setWithdrawAlertStatus("warning")
            setWithdrawAlert(`Minimum withdrawal ${minBlocksky} Blocksky`)
            return
        }
        setWithdrawAlertStatus("primary")
        setWithdrawAlert(`Processing..`)
        const login_token = localStorage.getItem('login_token')
        axios.post(`${endpoint}/api/v1/transaction/create`, {login_token: login_token , amount:amount} , {headers:{"X-API-KEY": api_key}})
        .then(response => {
            setDisabled(false)
            setWithdrawAlertStatus("success")
            setWithdrawAlert('Blocksky Token withdrawal successful.')
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        })
        .catch(error => {
            setDisabled(false)
            setWithdrawAlertStatus("danger")
            if(error.response.data){
                setWithdrawAlert(error.response.data.error)
            }else{
                setWithdrawAlert(error.message)
            }
        })
    }

    const [showToast, setShowToast] = useState(false);

    const alertToast = (msg , type , reload = false) => {
        setAlertBg(type)
        setAlertMsg(msg)
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
            if(reload){
                window.location.reload()
            }
        }, 5000);
    };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showFriend, setShowFriend] = useState(false);
  const handleFriendClose = () => setShowFriend(false);
  const handleShow = () => setShow(true);
  const toggleShowA = () => setShowToast(!showToast);
  
  return (
    <div>
        <div className="row">
          <div className="col-md-6">
            <div className={`balance-card ${props.profile ? 'balance-light' : ''}`}>
                <h4>Balance <small>({account})</small></h4>
                <div className='balance-text'>
                    <span className='balance'>{balance}</span>
                    <div className='currency'>
                        <span className='currency-blocksky'>BLOCKSKY</span>
                        <span className='usdPrice'>≈ ${balanceUsdPrice}</span>
                    </div>
                </div>
                <button type="button" onClick={() => setShow(true)} className="withdraw-link">
                    Withdraw
                </button>
            </div>
            
          </div>
            <div className="col-md-6">
                <div className={`balance-card ${props.profile ? 'balance-light' : ''}`}>
                    <h4>Balance <small>({account})</small></h4>
                    <div className='balance-text'>
                        <span className='balance'>{affiliateBalance}</span>
                        <div className='currency'>
                            <span className='currency-blocksky'>BLOCKSKY</span>
                            <span className='usdPrice'>≈ ${usdPriceAff}</span>
                        </div>
                    </div>
                    <button type="button" onClick={() => getFriends()} className="friends-btn">NFT Friends ({totalFriends})</button>
                </div>
            </div>
        </div>
        <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
            <Toast show={showToast} bg={alertBg} onClose={toggleShowA}>
                <Toast.Header>
                    <i className="fas fa-bell me-2"></i>
                    <strong className="me-auto">Alert</strong>
                </Toast.Header>
                <Toast.Body className={`${alertColor}`}>{alertMsg}</Toast.Body>
            </Toast>
        </ToastContainer>
        <Modal show={showFriend} onHide={handleFriendClose}>
            <Modal.Header className="share-modal-header" closeButton>
                <Modal.Title>NFT Friends</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card-balance mt-0 p-0" style={{boxShadow: 'none'}}>
                   <ul className="friends-list">
                        {friendElements}
                   </ul>
                </div>
            </Modal.Body>
        </Modal>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header className="share-modal-header" closeButton>
                <Modal.Title>Withdraw</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card-balance mt-0 p-0" style={{boxShadow: 'none'}}>
                    <h4>
                    Balance <small>({account})</small>
                    </h4>
                    <h3 className="balance-text">
                    {balance} <small>BLOCKSKY</small>
                    </h3>
                    <span>≈ ${balanceUsdPrice}</span>
                    <hr />
                    <div className={`my-2 ${withdrawAlert == "" ? "d-none": ""}`}>
                        <span className={`badge bg-${withdrawAlertStatus} text-white`}><i className="fas fa-bell"></i> {withdrawAlert}</span>
                    </div>
                    <div className="input-group mb-2">
                        <button
                            type="button"
                            onClick={maxBlocksky}
                            className="input-group-text max-btn"
                        >
                            Max
                        </button>
                        <input
                            type="number"
                            className="form-control"
                            ref={input}
                            defaultValue={0}
                        />
                        <span className="input-group-text blocksky-coin-text">
                            Blocksky
                        </span>
                    </div>
                    <div className="mb-2">
                        <small className="text-muted">Min : $50.00 ({minBlocksky} Blocksky)</small><br></br>
                        <small className="text-muted"><i className="fas fa-gas-pump"></i> $5.00 ({feeBlocksky} Blocksky)</small>
                    </div>
                    <button
                    type="button"
                    onClick={Withdraw}
                    disabled={disabled}
                    className="confirm-withdraw-btn"
                    >
                    Confirm
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    </div>
  );
}
