import logo from "./imgs/logo.png";
import i_twitter_purple from './imgs/i-twitter-purple.png';
import i_telegram_purple from './imgs/i-telegram-purple.png';
import i_face_purple from './imgs/i-face-purple.png';
import i_discord_purple from './imgs/i-discord-purple.png';

export default function Footer (props) {
  const footerClass = props === undefined ? "" : props.class
   return (
    <footer className={`footer ${footerClass}`}>
        <div className="container ss">
          <div className="footer-row animated wow fadeIn">
            <div className="footer-left">
              <div className="foot-logos">
                <img src={logo} alt="blocksky" />
              </div>
              <p>Share-to-Earn & Play-to-Earn
              Pays you to share.<br></br>
              <br></br>
              Blocksky app, it's an application that helps you make money and earn income easily from sharing cryptocurrency and Bitcoin news as well as other interesting news on social media.</p>
              <div className="foot-title">
                <span>Contact Us</span>
                <a href="https://www.blocksky.io/" className="ml-3 foot-title" title="Cryptocurrency & Bitcoin News">Blocksky News</a>
              </div>
              <p>&nbsp;</p>
              <span className="foot-title">Follow Us</span> 
              <a href="https://twitter.com/blockskycrypto" target="_blank" rel="noopener noreferrer" className="foot-icon twitt"><img src={i_twitter_purple} alt="twitter" /></a>
              <a href="https://t.me/+ja-BGSl1b6IzNzU1" target="_blank" rel="noopener noreferrer" className="foot-icon"><img src={i_telegram_purple} alt="telegram" /></a>
              <a href="https://www.facebook.com/blocksky.io" target="_blank" rel="noopener noreferrer" className="foot-icon"><img src={i_face_purple} alt="facebook" /></a>
            </div>
            <div className="footer-right">
              <div className="foot-row-menu">
                <div className="fmenu-item">
                  <div className="fmenu-head">About Us</div>
                  <div className="fmenu-row"><a href="https://www.blocksky.io/">Digital Media</a></div>
                  <div className="fmenu-row"><a href="https://www.blocksky.io/assets/img/Blocksky_Whitepaper_31.07.24.pdf" target="_blank" rel="noopener noreferrer">Whitepaper</a></div>
                </div>
                <div className="fmenu-item">
                  <div className="fmenu-head">Social Media</div>
                  <div className="fmenu-row"><a href="https://twitter.com/blockskycrypto" target="_blank" rel="noopener noreferrer">Twitter</a></div>
                  <div className="fmenu-row"><a href="https://t.me/+ja-BGSl1b6IzNzU1" target="_blank" rel="noopener noreferrer">Telegram</a></div>
                  <div className="fmenu-row"><a href="https://www.facebook.com/blocksky.io" target="_blank" rel="noopener noreferrer">Facebook</a></div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copy">All rights reserved © <a href>Blocksky</a> 2023</div>
        </div>
      </footer>
  )
}

    

