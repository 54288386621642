import React, { useState, useEffect } from "react";
import Axios from "axios";

function News({ newsKey }) {
  const [articles, setArticles] = useState([]);
  const Swiper = window.Swiper;
  const API_KEY = process.env.API_KEY;

  useEffect(() => {
    Axios.post(
      "https://www.blocksky.io/api/v1/get/articles",
      {
        type: "project",
        limit: "10",
        "X-API-KEY": API_KEY,
      }
    )
      .then((response) => {
        setArticles(response.data.articles);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (articles.length === 0) {
      return;
    }
  
    const swiper = new Swiper(".news-swiper", {
      navigation: {
        prevEl: ".news-slide-prev",
        nextEl: ".news-slide-next",
      },
      observer: true,
      observeParents: true,
      slidesPerView: 4,
      spaceBetween: 15,
      slidesPerGroup: 1,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      loop: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15,
        },
      },
    });
  
    const resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        swiper.update();
      }, 0);
    });
  
    const swiperEl = document.querySelector(".news-swiper");
    resizeObserver.observe(swiperEl);
  
    return () => {
      if (swiper) {
        swiper.destroy();
      }
      resizeObserver.disconnect();
    };
  }, [newsKey, articles]);
  
  
  return (
    <div className="container ss">
      <section className="sec-news animated wow fadeIn">
        <h3 className="text-gradient text-h3 text-center news-head">PROJECT NEWS</h3>
        <div className="slide-cover">
          <div className="swiper news-swiper">
            <div className="swiper-wrapper">
              {articles.map((article, index) => (
                <div key={article._id.$oid} className="swiper-slide news-slide-box">
                  <a href={article.url}>
                    <img
                      src={`https://www.blocksky.io/${article.img_cover}`}
                      alt={article.title}
                      className="news-img"
                    />
                  </a>
                  <div className="news-slide-title">{article.title}</div>
                  <div className="news-slide-btn">
                    <a href={article.url}>Read Article &gt;</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="slide-button news-slide-prev">
            <i className="fas fa-chevron-left" />
          </div>
          <div className="slide-button news-slide-next">
            <i className="fas fa-chevron-right" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default News;
