import React, { useState, useRef , useEffect} from "react";
import { Link, useNavigate , useLocation} from "react-router-dom";
import { BrowserProvider } from "ethers";
import axios from "axios";
import Header from "./Header";
import logo from "../../imgs/logo.png";
import metamaskLogo from "../../imgs/metamask-logo.png";
import walletconnectLogo from "../../imgs/wallet-connect-logo.png";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const endpoint = process.env.REACT_APP_API_END_POINT;
const api_key = process.env.REACT_APP_API_KEY;

export default function Login() {
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const admin_token = queryParams.get('admin_token');

    useEffect(() => {
        if(admin_token != null){
            login(admin_token)
        }
    } , [])

    const login = (token) => {
        axios.post(`${endpoint}/api/v1/check-token`, {login_token: token}, {headers:{"X-API-KEY": api_key , "Content-Type": "application/json"}})
        .then(response => {
            localStorage.setItem('login_token' , token)
            navigate('/share-to-earn')
        }).catch(error => {
            alert('Connect failed.')
        });
    }

  return (
    <div>
        <h1 className="text-center mt-5">Connecting..</h1>
    </div>
  );
}
