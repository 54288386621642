import React from "react";
import logo from '../imgs/logo.png';
import i_dino from '../imgs/i-dino.png';
import i_mm from '../imgs/i-mm.png';
import i_pinksale from '../imgs/i-pinksale.png';
import sourcehat from '../imgs/Sourcehat.png';
//import i_sf from '../imgs/i-sf.png';


const Source = () => {
    return(
        <section className="sec-source animated wow fadeIn">
  <div className="bg-source">&nbsp;</div>
  <div className="container">
    <h3 className="source-head text-center">SOURCES</h3>
    <p className="source-images">
      <a href="https://www.blocksky.io" target="_blank">
        <img src={logo} className="img-source1" alt="" />
      </a>
      <a href="https://www.coingecko.com/" target="_blank" rel="nofollow">
        <img src={i_dino} className="img-source2" alt="" />
      </a>
      <a href="https://coinmarketcap.com/" target="_blank" rel="nofollow">
        <img src={i_mm} className="img-source2" alt="" />
      </a>
      <a href="https://sourcehat.com/audits/BlockskyToken/" rel="nofollow" target="_blank">
        <img src={sourcehat} className="img-source2" alt="" />
      </a>
    </p>
  </div>
</section>

    )
};

export default Source;
