import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect, disconnect } from '../redux/blockchain/blockchainActions';

function ConnectButton() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

  const handleConnect = () => {
    if (blockchain.account) {
      // Disconnect logic
      dispatch(disconnect());
    } else {
      // Connect logic
      dispatch(connect());
    }
  };

  return (
    <div className="btn-cwallet mt-2 ml-0">
      <a href="javascript:void(0);" onClick={handleConnect}>
        {blockchain.account ? 'Log Out' : 'Connect'}
      </a>
    </div>
  );
}

export default ConnectButton;
