import ReactDOM from 'react-dom';
import React, { useState, useRef , useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "./Header";
import logo from '../../imgs/logo.png';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from "react-bootstrap";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Spinner from 'react-bootstrap/Spinner';

const endpoint = process.env.REACT_APP_API_END_POINT;
const api_key = process.env.REACT_APP_API_KEY;

function ShareToEarnDashboard(){

    const [alertBg , setAlertBg] = useState('success')
    const [alertColor , setAlertColor] = useState('text-white')
    const [alertMsg , setAlertMsg] = useState('')
    const toggleShowA = () => setShowToast(!showToast);
    const [showToast, setShowToast] = useState(false);

    const [news , setNews] = useState([])
    const [totalNews, setNewTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [link, setLink] = useState("")
    const [loading , setLoading] = useState(true)
    const [getting , setGetting] = useState(false)
    const [fbModal, setfbModal] = useState("")
    const [xModal, setXModal] = useState("")
    

    const perPage = 8

    useEffect(() => {
        getNews(currentPage)
    }, [])

    const getNews = (page) => {
        const login_token = localStorage.getItem('login_token')
        const data = {type: 'all', limit: 8, page: page}
        if(login_token){
            data.login_token = login_token
        }
        axios.post(`${endpoint}/api/v1/news`, data, {headers:{"X-API-KEY": api_key , "Content-Type": "application/json"}})
        .then(response => {
            setNews(response.data.data.news);
            setNewTotal(response.data.data.total);
            setLoading(false)
        })
        .catch(error => {
            console.error("Error fetching news:", error);
        });
    }

    const newsElements = news.map((item , index) => {
        let url = ""
        if(item.content_type == 'article'){
            url = 'https://www.blocksky.io/news/'+item.slug_title
        }else if(item.content_type == 'trending'){
            url = 'https://www.blocksky.io/trending/'+item.slug_title
        }else if(item.content_type == 'project'){
            url = 'https://www.blocksky.io/projects/'+item.slug_title
        }

        let old_link = null
        if(item.shared != undefined){
            if(item.shared[0]){
                old_link = item.shared[0].news_link
            }
        }

        return <div className="news-item" key={index}>
                    <Link to={url} target='_blank' className='read-news'>
                        <div className='cover-image' style={{ backgroundImage: "url('https://www.blocksky.io/"+item.img_cover+"')" }}></div>
                    </Link>
                    <div className="news-body">
                        <h6>{item.title}</h6>
                        <span className='news-date'>{item.created_at}</span>
                        <div className="news-action">
                            {old_link && (
                                <div className="form-inline gap-2">
                                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(old_link)}`} target="_blank" rel="noopener noreferrer" className='share-to'>
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1024px-Facebook_Logo_%282019%29.png" alt="Facebook" />
                                    </a>
                                    <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(old_link)}`} target="_blank" rel="noopener noreferrer" className='share-to'>
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7fj1vqat9XLO4cFwOG1uFqLXYDcISiYil2w&usqp=CAU" alt="Twitter" />
                                    </a>
                                </div>
                            )}
                            <button type='button' id={`btn-link-${index}`} onClick={() => getLink(item._id , old_link , index)} className='get-link-btn'><i className="fas fa-link"></i> Get Link</button>
                        </div>
                    </div>
                </div>
    })

    const getLink = (id , link = null , index) => {
        let btn = document.getElementById('btn-link-'+index)
        ReactDOM.render(
            <div className='d-flex gap-1 align-items-center'>
            <Spinner animation="grow" size='sm' variant='light' role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            Get Link..
            </div>,
            btn
        );
        if(!getting){
            if(link == null){
                setGetting(true)
                const login_token = localStorage.getItem('login_token')
                const news_id = id
                axios.post(`${endpoint}/api/v1/news/create-link`, {login_token: login_token , news_id:news_id}, {headers:{"X-API-KEY": api_key , "Content-Type": "application/json"}})
                .then(response => {
                    ReactDOM.render(
                        <div className='d-flex gap-1 align-items-center'>
                            <i className="fas fa-link"></i> Get Link
                        </div>,
                        btn
                    );
                    const news_link = response.data.news_link
                    console.log(news_link)
                    setfbModal(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(news_link)}`)
                    setXModal(`https://twitter.com/intent/tweet?url=${encodeURIComponent(news_link)}`)
                    setLink(news_link)
                    setShow(true)
                    setGetting(false)
                })
                .catch(error => {
                    setGetting(false)
                    ReactDOM.render(
                        <div className='d-flex gap-1 align-items-center'>
                            <i className="fas fa-link"></i> Get Link
                        </div>,
                        btn
                    );
                    if(error.response){
                        let errData = error.response.data
                        alertToast(errData.error , 'danger')
                    }else{
                        alertToast(error.message , 'danger')
                    }
                });
            }else{
                ReactDOM.render(
                    <div className='d-flex gap-1 align-items-center'>
                        <i className="fas fa-link"></i> Get Link
                    </div>,
                    btn
                );
                setfbModal(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`)
                setXModal(`https://twitter.com/intent/tweet?url=${encodeURIComponent(link)}`)
                setShow(true)
                setLink(link)
            }
        }
    }

    const handlePageClick = (e) => {
        let selectedPage = e.selected;
        selectedPage = selectedPage + 1
        const offset = selectedPage * perPage;
        setCurrentPage(selectedPage)
        getNews(selectedPage)
    };
    
    function shortenText(text, maxLength) {
        if (text.length <= maxLength) {
          return text;
        } else {
          return text.substring(0, maxLength) + "...";
        }
    }

    const copyToClipboard = () => {
        const linkInput = document.getElementById('link-input');
        linkInput.classList.remove('d-none'); 
        linkInput.select(); 
        document.execCommand('copy');
        linkInput.classList.add('d-none');
        alert('Link copied to clipboard!')
        // alertToast('Link copied to clipboard!' , 'success')
    };

    const alertToast = (msg , type ) => {
        setAlertBg(type)
        setAlertMsg(msg)
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 2000);
    };

      
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
        <div className="share-body">
            <Header page="dashboard"></Header>
            <div className="container mt-4">
                <div className="news-wrappper">
                {loading ? (
                            <div className='loading-data'>
                                <Spinner animation="grow" variant='light' role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                                <span className='text-white loading-text'>Loading...</span>
                            </div>
                        ) : null}

                    {newsElements}
                    <ReactPaginate
                        previousLabel={<i className="fas fa-angle-left"></i>}
                        nextLabel={<i className="fas fa-angle-right"></i>}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={totalNews}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"news-pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}/>
                </div>
            </div>
            <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
                <Toast show={showToast} bg={alertBg} onClose={toggleShowA}>
                    <Toast.Header>
                        <i className="fas fa-bell me-2"></i>
                        <strong className="me-auto">Alert</strong>
                    </Toast.Header>
                    <Toast.Body className={`${alertColor}`}>{alertMsg}</Toast.Body>
                </Toast>
            </ToastContainer>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className='share-modal-header' closeButton>
                    <Modal.Title>Link for sharing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='form-inline'> 
                            <input type="text" id='link-input' className='d-none' value={link} />
                            <span className='link-sharing'>
                                Link : {link ? shortenText(link , 40) : link}
                                <button type='button' className='copy-btn' onClick={copyToClipboard}><i className="fas fa-copy"></i></button>
                            </span>
                        </div> 
                        <div className="form-inline gap-3 mt-3">
                            <span>Share to : </span>
                            <a href={fbModal} target='_blank' className='share-to'><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1024px-Facebook_Logo_%282019%29.png" /></a>
                            <a href={xModal} target='_blank' className='share-to'><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7fj1vqat9XLO4cFwOG1uFqLXYDcISiYil2w&usqp=CAU" alt="" /></a>
                        </div>
                    </div>
                    <div className="form-inline mt-2">
                        <Button className='btn-sm ml-auto' variant="danger" onClick={handleClose}>Close</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ShareToEarnDashboard;