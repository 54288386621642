import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Sec1project from '../components/Sec1project';
import Sec2gallery from '../components/Sec2gallery';
import Sec3unique from '../components/Sec3unique';
import Sec4shareto from '../components/Sec4shareto';
import Sec5invest from '../components/Sec5invest';
import Sec6tokenview from '../components/Sec6tokenview';
import Sec7staking from '../components/Sec7staking';
import Sec8roadmap from '../components/Sec8roadmap';
import Sec9source from '../components/Sec9source';
import Sec10community from '../components/Sec10community';
import Sec11news from '../components/Sec11news';
import { useLocation } from 'react-router-dom';

const ProjectPage = () => {
  const location = useLocation();
  const newsKey = location.key;
  
    return (
        <div>
            <div className="bg-main1 animated wow fadeIn">
            <Header />
            <Sec1project />
            </div>
            <Sec2gallery />
            <Sec3unique />
            <Sec4shareto />
            <Sec5invest />
            <Sec6tokenview />
            <Sec7staking />
            <div className="bg-merge animated wow fadeIn">
            <div className="bg-main2">&nbsp;</div>
            <Sec8roadmap />
            <Sec9source />
            </div>
            <Sec10community />
            <Sec11news key={newsKey} />
            <Footer />
        </div>
    )
};

export default ProjectPage;
