import React, { useState, useRef , useEffect} from "react";
import { Link, useNavigate , useParams} from "react-router-dom";
import { BrowserProvider } from "ethers";
import axios from "axios";
import Header from "./Header";
import logo from "../../imgs/logo.png";
import metamaskLogo from "../../imgs/metamask-logo.png";
import walletconnectLogo from "../../imgs/wallet-connect-logo.png";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useWeb3ModalProvider , useWeb3ModalAccount } from '@web3modal/ethers/react'
import { createWeb3Modal , useWeb3Modal , defaultConfig } from '@web3modal/ethers/react'
import { WalletConnectModal } from '@walletconnect/modal'

const endpoint = process.env.REACT_APP_API_END_POINT;
const api_key = process.env.REACT_APP_API_KEY;

export default function Login() {

  const [alertBg , setAlertBg] = useState('success')
  const [alertColor , setAlertColor] = useState('text-white')
  const [alertMsg , setAlertMsg] = useState('')
  const toggleShowA = () => setShowToast(!showToast);
  const [showToast, setShowToast] = useState(false);
  const [loading , setLoading] = useState(false)
  const navigate = useNavigate();
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  let loginInterval;


  useEffect(() => {
    let login_token = localStorage.getItem('login_token')
    if(address & !login_token){
      login()
    }  
  } , [])


  const login = () => {
    if(address && !loading){
      clearInterval(loginInterval);
      setLoading(true)
      alertToast("Connecting.." , 'primary')
      axios.post(`${endpoint}/api/v1/login`, {crypto_address: address} , {headers:{"X-API-KEY": api_key , "Content-Type": "application/json"}})
      .then(response => {
        localStorage.setItem('login_token' , response.data.token)
        alertToast("Logged in." , 'success')
        setLoading(false)
        close()
        setTimeout(() => {
          navigate('/share-to-earn')
        }, 1500);
      })
      .catch(error => {
        if(error.response.data){
          setLoading(false)
          clearInterval(loginInterval);
          alertToast(error.response.data.error , 'danger')
        }else{
          setLoading(false)
          clearInterval(loginInterval);
          alertToast(error.message , 'danger')
        }
      });
    }
  };

  const alertToast = (msg , type ) => {
    setAlertBg(type)
    setAlertMsg(msg)
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 5000);
  };

  const projectId = 'a9301ad966e88443666709ae6ad496c7'

  const mainnet = {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com'
  }

  const metadata = {
    name: 'Blocksky - Share to Earn',
    description: 'Share to Earn & Play to Earn on Blocksky app',
    url: 'https://app.blocksky.io',
    icons: ['https://www.blocksky.io/assets/img/blocksky.ico']
  }

  const ethersConfig = defaultConfig({
    metadata,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: '...', 
    defaultChainId: 1,
  })

  createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: true 
  })

  const { open , close } = useWeb3Modal();

  const connectWallet = async () => {
    try {
      await open()
      login()
    } catch (error) {
      console.error('Error connecting wallet:', error);
    }
  };


  return (
    <div className="share-body">
      <Header page="login"></Header>
      <div className="container">
        <div className="login-body">
          <div className="login-card">
            <h3 className="text-white">Connect Wallet</h3>
            <div className="wallets-choice">
              <button type="button" onClick={connectWallet} className="wallet-btn wallet-connect">
                <img src={walletconnectLogo} alt="" /> WalletConnect
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
          <Toast show={showToast} bg={alertBg} onClose={toggleShowA}>
              <Toast.Header>
                  <i className="fas fa-bell me-2"></i>
                  <strong className="me-auto">Alert</strong>
              </Toast.Header>
              <Toast.Body className={`${alertColor}`}>{alertMsg}</Toast.Body>
          </Toast>
      </ToastContainer>
    </div>
  );
}
