import React, { useState, useRef, useEffect, createRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Ethereum_logo from "../../imgs/Ethereum_logo.svg";
import nftLogoBlack from "../../imgs/nft-black.png";
import nftIconNon from "../../imgs/nft-icon-non.jpeg";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Wallet from "./components/Wallet";
const endpoint = process.env.REACT_APP_API_END_POINT;
const api_key = process.env.REACT_APP_API_KEY;

function ShareToEarnDashboard() {
  const [account, setAccount] = useState();
  const [user, setUser] = useState(null);
  const [advisorCode, setAdvisorCode] = useState("");
  const advisorRef = createRef();
  const [nftAlert, setNftAlert] = useState("");
  const [totalNft, setTotalNft] = useState(0);
  const [affilateCode, setAffiliateCode] = useState("");
  const updateBtn = createRef()
  const [checking , setChecking] = useState(false)
  const [alertBg, setAlertBg] = useState("success");
  const [alertColor, setAlertColor] = useState("text-white");
  const [alertMsg, setAlertMsg] = useState("");
  const [nftImage , setNftImage] = useState(nftLogoBlack)
  const [priceUsd , setPriceUsd] = useState(0)

  useEffect(() => {
    getUser();
    getAccount()
  }, []);

  const updateNft = () => {
    alertToast('Connecting..' , 'primary')
    if(!checking){
      setChecking(true)
      const login_token = localStorage.getItem("login_token");
      if (login_token) {
        axios
          .post(`${endpoint}/api/v1/updateNft`, { login_token: login_token }, {headers:{"X-API-KEY": api_key , "Content-Type": "application/json"}})
          .then((response) => {
            const data = response.data
            if(data.total_nft > 0){
              setNftImage(`${endpoint}/api/v1/nft/${data.tokens[0]}`)
            }
            setChecking(false)
            alertToast('NFT connected !.' , 'success')
          })
          .catch((error) => {
            if (error.response.data) {
              setChecking(false)
              alertToast(error.response.data.error , 'danger')
            } else {
              setChecking(false)
              alertToast(error.response.data.error , 'danger')
            }
          });
      }
    }
  };

  const getAccount = () => {
      let login_token = localStorage.getItem('login_token')
        if(login_token){
        axios.post(`${endpoint}/api/v1/check-token`, {login_token: login_token}, {headers:{"X-API-KEY": api_key , "Content-Type": "application/json"}})
        .then(response => {
            let walletAddress = response.data.walletAddress;
            setAccount(
                walletAddress.substring(0, 5) + "..." + walletAddress.substring(walletAddress.length - 5)
            );
        }).catch(error => {
            localStorage.removeItem('login_token')
        });
      }
  };

  const getUser = () => {
    const login_token = localStorage.getItem("login_token");
    if (login_token) {
      axios
        .post(`${endpoint}/api/v1/user`, { login_token: login_token }, {headers:{"X-API-KEY": api_key , "Content-Type": "application/json"}})
        .then((response) => {
          let user = response.data.user;
          if(user){
            setUser(user)
            setPriceUsd(response.data.priceUsd)
            if(user.total_nft){
              if(user.total_nft > 0){
                setTotalNft(user.total_nft)
                setAffiliateCode(user.affiliate_code)
                // setNftImage(`${endpoint}/api/v1/nft/${user.nft[0]}`)
                fetch(`${endpoint}/api/v1/nft/${user.nft[0]}`)
                .then(response => {
                  if (response.status === 404) {
                    setNftImage(nftIconNon)
                  } else {
                    setNftImage(`${endpoint}/api/v1/nft/${user.nft[0]}`)
                  }
                })
                .catch(error => {
                  setNftImage(nftIconNon)
                });

              }
            }
            if(user.advisor_code){
              setAdvisorCode(user.advisor_code);
            }
          }
        })
        .catch((error) => {
          console.error("Error", error);
        });
    }
  };

  const updateAdvisor = () => {
    const code = advisorRef.current.value;
    if (code == "") {
      alert("Enter Affiliate code.");
      return;
    }
    const login_token = localStorage.getItem("login_token");
    axios
      .post(`${endpoint}/api/v1/update-advisor`, {
        login_token: login_token,
        affiliate_code: code,
      }, {headers:{"X-API-KEY": api_key , "Content-Type": "application/json"}})
      .then((response) => {
        alertToast('Updated.' , 'success')
        setAdvisorCode(code);
      })
      .catch((error) => {
        if (error.response.data) {
          alert(error.response.data.error);
        } else {
          alert(error.message);
        }
      });
  };

  const [showToast, setShowToast] = useState(false);

  const alertToast = (msg , type , reload = false) => {
      setAlertBg(type)
      setAlertMsg(msg)
      setShowToast(true);
      setTimeout(() => {
          setShowToast(false);
          if(reload){
              window.location.reload()
          }
      }, 5000);
  };

  const copyToClipboard = () => {
    const linkInput = document.getElementById("affiliate-input");
    linkInput.classList.remove("d-none");
    linkInput.select();
    document.execCommand("copy");
    linkInput.classList.add("d-none");
    alert("Link copied to clipboard!");
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const toggleShowA = () => setShowToast(!showToast);
  return (
    <div className="share-body">
      <Header page="profile"></Header>
      <div className="container mt-4">
        {user ? <Wallet user={user} priceUsd={priceUsd} profile={true} ></Wallet> : ""}
        <div className="row">
          <div className="col-md-4">
            <div className="white-card">
              <div className="nft-wrapper-check">
                <div className="nft-profile" style={{backgroundImage: "url('"+nftImage+"')"}}>
                </div>
                <div className="nft-info">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <img src={Ethereum_logo} style={{ width: "15px" }} />
                    <span>{account}</span>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <span>Connected NFT </span>
                    <span className="text-muted">({totalNft})</span>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <span className="text-danger">{nftAlert}</span>
                <button
                  type="button"
                  className="update-nft-btn"
                  onClick={updateNft}
                  ref={updateBtn}
                >
                  Update Blocksky NFT
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="white-card ">
              <div className="d-flex align-items-center gap-2 mb-2">
                <i className="fas fa-users"></i>
                <span>Sponsor's code.</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <small className="text-muted">
                  Enter your sponsor's code to get 5% cash back.
                </small>
              </div>
              {advisorCode == "" ? (
                <div className="input-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    ref={advisorRef}
                    placeholder="Affiliate Code"
                  />
                  <button
                    type="button"
                    className="input-group-button"
                    onClick={updateAdvisor}
                  >
                    Confirm
                  </button>
                </div>
              ) : (
                <input
                  type="text"
                  className="form-control mt-3"
                  style={{ height: "54px" }}
                  defaultValue={advisorCode}
                  readOnly
                />
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="white-card ">
              <div className="d-flex align-items-center gap-2 mb-2">
                <i className="fas fa-users"></i>
                <span>Affiliate to friends</span>
                <button
                  type="button"
                  onClick={handleShow}
                  className="rules-btn"
                >
                  <i className="fas fa-book-open"></i> Rules
                </button>
              </div>
              <div className="d-flex align-items-center gap-2">
                <small className="text-muted">
                  Earn 10% from inviting your friends to join share-to-earn.
                </small>
              </div>
              <div className="user-affiliate mt-3">
                <input
                  type="text"
                  value={affilateCode}
                  className="d-none"
                  id="affiliate-input"
                />
                <span>{affilateCode}</span>
                <button
                  type="button"
                  className="copy-aff-btn"
                  onClick={copyToClipboard}
                >
                  <i className="fas fa-copy"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
            <Toast show={showToast} bg={alertBg} onClose={toggleShowA}>
                <Toast.Header>
                    <i className="fas fa-bell me-2"></i>
                    <strong className="me-auto">Alert</strong>
                </Toast.Header>
                <Toast.Body className={`${alertColor}`}>{alertMsg}</Toast.Body>
            </Toast>
        </ToastContainer>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="share-modal-header" closeButton>
          <Modal.Title>Affiliate Rules</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span>
            An affiliate earns a commission from marketing Blocksky NFTs to other people. <br/>
            </span>
            <span> An affiliate earns 25% of NFT price or 0.01 ETH for each NFT that can be sold.</span>
          </div>
          <div className="form-inline mt-2">
            <Button
              className="btn-sm ml-auto"
              variant="danger"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ShareToEarnDashboard;
