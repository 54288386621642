import React from 'react'
import Header from '../Header';
import Footer from '../Footer';
import twitter from '../imgs/twitter.png';
import telegram from '../imgs/telegram.png';
import facebook from '../imgs/facebook.png';
//import discord from '../imgs/discord.png';


const NotFound = () => {
    return(
        <div>
            <div className="bg-main1 animated wow fadeIn">
            <Header />
            <section className="sec-project">
  <div className="container">
    <div className="pj-coming">
      <h1 className="h1-coming">404 Page Not Found!</h1>
    </div>
    <div className="pj-social">
      <a href="https://twitter.com/blockskycrypto" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="" /></a>
      <a href="https://t.me/+ja-BGSl1b6IzNzU1" target="_blank" rel="noopener noreferrer"><img src={telegram} alt="" /></a>
      <a href="https://www.facebook.com/blocksky.io" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="" /></a>
    </div>
  </div>
</section>
</div>
            <Footer />
        
        </div>
    )
};


export default NotFound;