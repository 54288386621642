import React, { useState, useRef, useEffect, createRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "bootstrap/dist/css/bootstrap.min.css";
import Wallet from "./components/Wallet";
import Table from "react-bootstrap/Table";
import DatePicker, { registerLocale } from "react-datepicker";
import enUS from 'date-fns/locale/en-US';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('en-US', enUS);

const endpoint = process.env.REACT_APP_API_END_POINT;
const api_key = process.env.REACT_APP_API_KEY;

export default function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState(0.0);
  const [priceUsd , setPriceUsd] = useState(0)
  const [totalTx, setTotalTx] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const filterDate = createRef();
  const filterType = createRef();
  const filterStatus = createRef();
  const perPage = 10;

  useEffect(() => {
    getUser();
    getTransactions(currentPage);
  }, []);

  const getUser = () => {
    const login_token = localStorage.getItem("login_token");
    if (login_token) {
      axios
        .post(`${endpoint}/api/v1/user`, { login_token: login_token }, {headers:{"X-API-KEY": api_key , "Content-Type": "application/json"}})
        .then((response) => {
          let user = response.data.user;
          if(user){
            setUser(user)
            setPriceUsd(response.data.priceUsd)
          }
        })
        .catch((error) => {
          console.error("Error", error);
        });
    }
  };

  const getTransactions = (page = currentPage) => {
    const login_token = localStorage.getItem("login_token");
    const tx_type = filterType.current.value;
    const tx_status = filterStatus.current.value;
    let tx_date = selectedDate == null ? "" : new Date(selectedDate);
    if(tx_date != ""){
      tx_date = `${tx_date.getFullYear()}-${(tx_date.getMonth() + 1).toString().padStart(2, '0')}-${tx_date.getDate().toString().padStart(2, '0')}`;
    }
    if (login_token) {
      axios
        .post(`${endpoint}/api/v1/transactions`, {
          login_token: login_token,
          page: page,
          limit: perPage,
          tx_date: tx_date,
          tx_type: tx_type,
          tx_status: tx_status,
        }
        , {headers:{"X-API-KEY": api_key , "Content-Type": "application/json"}})
        .then((response) => {
          setTransactions(response.data.transactions);
          const totalPages = Math.ceil(response.data.total / perPage);
          setTotalTx(totalPages);
        })
        .catch((error) => {
          console.error("Error", error);
        });
    }
  };

  const txEls = transactions.map((tx, index) => {
    let address = tx.crypto_address;
    let txHash = tx.transactionHash;
    let status = tx.status;
    let transaction_type = "Withdraw";
    let amount = parseFloat(tx.amount);
    let txFrom = "-";
    if (address) {
      address =
        address.substring(0, 5) + "..." + address.substring(address.length - 5);
    }
    if (txHash) {
      txHash =
        txHash.substring(0, 5) + "..." + txHash.substring(txHash.length - 5);
    }
    if (status == 1) {
      status = <span className="badge badge-success">Completed</span>;
    } else if (status == 0) {
      status = <span className="badge badge-dark">Waiting</span>;
    } else if (status == 2) {
      status = <span className="badge badge-danger">Canceled</span>;
    } else if (status == 3) {
      status = <span className="badge badge-primary">Blocking</span>;
    }

    if (tx.transaction_type == "deposit") {
      transaction_type = "Deposit";
    }

    if (tx.from) {
      if (tx.from == "earn") {
        txFrom = "Sharing";
      } else if (tx.from == "affiliate") {
        txFrom = "Affiliate Sharing";
      } else if (tx.from == "refund") {
        txFrom = "Discount";
      } else if (tx.from == "nft") {
        txFrom = "NFT Affiliate";
      }
    }

    const timestamp = tx.created_at;
    const date = new Date(timestamp);
    const formattedDate = date.toISOString().slice(0, 19).replace("T", " ");

    return (
      <tr>
        <td>{formattedDate}</td>
        <td>{transaction_type}</td>
        <td>{txFrom}</td>
        <td
          className={
            tx.transaction_type === "withdraw" ? "text-danger" : "text-success"
          }
        >
          {tx.transaction_type === "withdraw" ? "-" : "+"}
          {amount.toFixed(2)}
        </td>
        <td>
          {address}
          {address ? (
            <button type="button" className="copy-btn-dark text-primary">
              <i className="fas fa-copy"></i>
            </button>
          ) : (
            ""
          )}
        </td>
        <td>
          {txHash}
          {txHash ? (
            <a
              href={tx.transactions_link}
              target="_blank"
              className="copy-btn-dark  text-primary mx-2"
            >
              <i className="fas fa-link"></i>
            </a>
          ) : (
            ""
          )}
          {txHash ? (
            <button type="button" className="copy-btn-dark text-primary">
              <i className="fas fa-copy"></i>
            </button>
          ) : (
            ""
          )}
        </td>
        <td>{status}</td>
      </tr>
    );
  });

  const handlePageClick = (e) => {
    let selectedPage = e.selected;
    selectedPage = selectedPage + 1;
    setCurrentPage(selectedPage);
    getTransactions(selectedPage);
  };

  const resetFilter = () => {
    filterDate.current.value = "";
    filterType.current.value = "";
    filterStatus.current.value = "";
    getTransactions();
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = date => {
    setSelectedDate(date);
  };
  
  return (
    <div className="share-body">
      <Header page="transactions"></Header>
      <div className="container mt-2">
        
        {/* <h4 className="db-font  mt-2">Transaction History</h4> */}
        <div className="transaction-table">
          {user ? <Wallet user={user} priceUsd={priceUsd} ></Wallet> : ""}
          <div className="table-filter">
            {/* <input type="date" ref={filterDate} lang="en" /> */}
            <div className="input-group flex-no-wrap">
                <label className="input-group-text">Date</label>
                <DatePicker
                locale={enUS}
                ref={filterDate} 
                selected={selectedDate}
                onChange={handleDateChange}
                placeholder="Select date"
                className="form-select"
              />
            </div>
            <div className="input-group">
              <label className="input-group-text">Type</label>
              <select className="form-select" ref={filterType} defaultValue="">
                <option value="">Choose...</option>
                <option value="withdraw">Withdraw</option>
                <option value="earn">Sharing</option>
                <option value="refund">Discount</option>
                <option value="nft">NFT Affiliate</option>
                <option value="affiliate">Affiliate Sharing</option>
              </select>
            </div>
            <div className="input-group">
              <label className="input-group-text">Status</label>
              <select
                className="form-select"
                ref={filterStatus}
                defaultValue=""
              >
                <option value="">Choose...</option>
                <option value="0">Waiting</option>
                <option value="1">Completed</option>
                <option value="2">Canceled</option>
                <option value="3">Blocking</option>
              </select>
            </div>
            <button
              className="search-btn"
              onClick={() => getTransactions(currentPage)}
            >
              <i className="fas fa-search"></i>
            </button>
            <button className="reset-btn" onClick={resetFilter}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="table-responsive">
            <Table className="w-100 table-hover">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Type</th>
                  <th>From</th>
                  <th>Amount</th>
                  <th>Destination</th>
                  <th>TxID</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>{txEls}</tbody>
            </Table>
          </div>
          <ReactPaginate
            previousLabel={<i className="fas fa-angle-left"></i>}
            nextLabel={<i className="fas fa-angle-right"></i>}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalTx}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"news-pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
}
