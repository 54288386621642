import React, { useEffect, useState } from "react";
import { connect } from '../redux/blockchain/blockchainActions';
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import i_opensea from '../imgs/i-opensea.png';
import gl2_thumbs from '../imgs/gl1-thumbs.webp?v=1';
import nfts_twitter from '../imgs/nfts-twitter.png';
import nfts_telegram from '../imgs/nfts-telegram.png';
import nfts_www from '../imgs/nfts-www.png';
import nfts_papers from '../imgs/nfts-papers.png';
import nfts_opensea from '../imgs/nfts-opensea.png';
import nfts_github from '../imgs/nfts-github.png';
import logo_nfts_foot from '../imgs/logo-nfts-foot.png';
import MintHeader from '../MintHeader';

function MintAppPage() {

  function handleClick(event) {
    event.preventDefault();
    alert("coming soon");
 }

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `${CONFIG.NFT_NAME} is yours!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);
 
    return (
        <div className="bg-nfts animated wow fadeIn">
     <MintHeader />
  <section className="sec-thumbs1">
    <div className="container">
      <div className="box-thumbs1">
        <div className="thumbs1-left animated wow bounceInLeft">
          <h1 className="text-gradient-nfts text-nfts-h1">More than just</h1>
          <h2 className="text-gradient-nfts2 text-nfts-h1 mt-drop">an artwork.</h2>
          <h3 className="thumbs1-h3">Blocksky NFTs <br />are NFT share-to-earn.</h3>
          <h4 className="thumbs1-h4"> 0.03 ETH per NFT.</h4>
          <div className="thumbs1-button">
          {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
              <p>The sale has ended.</p>      
              </>
            ) : (
              <>
              {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <div> 
                    <a type="submit" className="btn-nfts-style"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      Mint
                    </a>
                    <a href="https://opensea.io/BlockskyNFT" className="btn-nfts-style style2" target="_blank" rel="noopener noreferrer">
                    <img src={i_opensea} alt="opensea" />
                      Opensea
                    </a>
                    {blockchain.errorMsg !== "" ? (
                      <>
                          {blockchain.errorMsg}
                      </>
                    ) : null}
                    
                  </div>
                ) : (
                  <>  
                      <a type="submit" className="btn-nfts-style"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "Mint"}
                      </a>
                      <a href="https://opensea.io/BlockskyNFT" className="btn-nfts-style style2" target="_blank" rel="noopener noreferrer">
                      <img src={i_opensea} alt="opensea" />
                        Opensea
                      </a>
                  </>
                )}
              </>
            )}
            {blockchain.account !== "" && blockchain.smartContract !== null ? (
              <div>Wallet Address: xxx{blockchain.account.substring(blockchain.account.length - 5)}</div>
            ) : (
              <div>Please connect to your {CONFIG.NETWORK.NAME} Wallet.</div>
            )}
          </div>
          <div>{feedback}</div>
        </div>
        <div className="thumbs1-right">
          <img src={gl2_thumbs} alt="" />
        </div>
      </div>
    </div>
  </section>
  <section className="sec-joincommunity">
    <div className="container">
      <div className="box-jcommunity">
        <h2 className="jcom-header">Join Our Community</h2>
        <div className="jcom-button">
          <a href="https://twitter.com/blockskycrypto" target="_blank" rel="noopener noreferrer" className="btn-jcommunity">
            <span className="bg-thumbs">
              <img src={nfts_twitter} alt="twitter" />
            </span>
            <span className="text">Twitter</span>
          </a>
          <a href="https://t.me/+ja-BGSl1b6IzNzU1" target="_blank" rel="noopener noreferrer" className="btn-jcommunity">
            <span className="bg-thumbs">
              <img src={nfts_telegram} alt="telegram" />
            </span>
            <span className="text">Telegram</span>
          </a>
          <a href="https://www.blocksky.io/" target="_blank" rel="noopener noreferrer" className="btn-jcommunity">
            <span className="bg-thumbs">
              <img src={nfts_www} alt="website" />
            </span>
            <span className="text">Website</span>
          </a>
        </div>
        <h2 className="jcom-header">Documentation</h2>
        <div className="jcom-button">
          <a href="https://www.blocksky.io/Blocksky-Whitepaper.pdf" target="_blank" rel="noopener noreferrer" className="btn-jcommunity">
            <span className="bg-thumbs">
              <img src={nfts_papers} alt="whitepaper" />
            </span>
            <span className="text">Whitepaper</span>
          </a>
          <a href="https://opensea.io/BlockskyNFT" target="_blank" rel="noopener noreferrer" className="btn-jcommunity">
            <span className="bg-thumbs">
              <img src={nfts_opensea} alt="opensea" />
            </span>
            <span className="text">Opensea</span>
          </a>
          <a href="/mint" target="_blank" rel="noopener noreferrer" className="btn-jcommunity" onClick={handleClick}>
            <span className="bg-thumbs">
              <img src={nfts_github} alt="github" />
            </span>
            <span className="text">GitHub</span>
          </a>
        </div>
      </div>
    </div>
  </section>
  <footer className="nfts-bgfooter">
    <div className="container">
      <div className="nfts-footer">
        <div className="nfts-fleft">
          <a href>
            <img src={logo_nfts_foot} alt="blocksky nfts" />
          </a>
        </div>
        <div className="nfts-fright">All rights reserved <span className="blue">© Blocksky NFTs</span> 2023</div>
      </div>
    </div>
  </footer>
</div>

    )
}
    

export default MintAppPage;
