import React from "react";

const Community = () => {
    return(
        <section className="sec-community">
  <div className="container sm">
    <div className="box-community">
      <div className="commu-items">
        <h3 className="in-head text-gradient text-h3">JOIN THE COMMUNITY</h3>
        <p className="commu-detail">
          Our goal is to make our community strong and getting <br />
          bigger as well as making our website well-known.
        </p>
        <div>
          <a href="https://t.me/+ja-BGSl1b6IzNzU1" target="_blank" className="btn-bsky-style">Join Telegram</a>&nbsp;
          <a href="https://twitter.com/blockskycrypto" target="_blank" className="btn-bsky-style">Twitter</a>
        </div>
      </div>
    </div>
  </div>
</section>
    )
};

export default Community;