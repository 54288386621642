import React from "react";

const Unique = () => {
    return(
        <section className="sec-unique">
        <div className="container">
          <h3 className="text-gradient vtoken-head">20,000 UNIQUE NFT COLLECTIBLES.</h3>
          <p className="vtoken-text">Token Standard ERC-721 </p>
        </div>
      </section>
    )
};

export default Unique;