import React from "react";
import invest_thumbs from '../imgs/invest-thumbs.webp';
import icon_optimism from '../imgs/icon-optimism.png';
import { Link } from 'react-router-dom';

const Invest = () => {
    return(
        <section className="sec-invest animated wow fadeIn">
        <div className="container">
          <div className="box-invest">
            <div className="invest-items in-thumbs animate wow fadeInLeft">
              <img src={invest_thumbs} alt="" />
            </div>
            <div className="invest-items">
              <h3 className="in-head text-gradient text-h3">
              Blocksky Token (BLOCKSKY)
              </h3>
              <p className="in-detail">
              Blocksky Token is used within the Blocksky platform. <br></br>
              Share-to-earn users earn BLOCKSKY for sharing news and articles from blocksky.io. Advertisers get discounts when purchase advertisements on blocksky.io with Blocksky Token.
              </p>
              <div className="token-address">
                <img src={icon_optimism} width={25}></img> <span><strong> OP Mainnet : </strong></span> <span>0x5cCb63db1AF44E93095AF45aA820a2109DFecfE6</span>
              </div>
              <div>
                <a href="https://www.blocksky.io/assets/img/Blocksky_Whitepaper_31.07.24.pdf" className="btn-bsky-style sm" target="_blank">Learn more</a>
                <Link to="/token" className="btn-bsky-style sm">Token</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
};

export default Invest;
