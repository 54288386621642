import React from "react";
import pj_nft from '../imgs/pj-nft.png';
import pj_shareto from '../imgs/pj-shareto.png';
import pj_trade from '../imgs/pj-trade.png';
import pj_stake from '../imgs/pj-stake.png';
import twitter from '../imgs/twitter.png';
import telegram from '../imgs/telegram.png';
import facebook from '../imgs/facebook.png';
import blockskyTokenBanner from '../imgs/new-banner1.png';

//import discord from '../imgs/discord.png';

const Project = () => {
    return(
        <section className="sec-project">
        <div className="container">
          <div>
              <img className="w-100 mb-5 mt-2 rounded" src={blockskyTokenBanner}></img>
          </div>
          <h1 className="text-gradient text-h2">Blocksky Projects</h1>
          <div className="pj-views animated wow bounceInUp">
            <div className="pj-items">
              <div className="pj-bg">&nbsp;</div>
              <div className="pj-thumbs">
                <img src={pj_nft} alt="" />
              </div>
              <div className="pj-topic">NFTs</div>
              <div className="pj-detail">Blocksky NFT collection.</div>
            </div>
            <div className="pj-items shareto">
              <div className="pj-bg">&nbsp;</div>
              <div className="pj-thumbs">
                <img src={pj_shareto} alt="" />
              </div>
              <div className="pj-topic">Share-to-Earn</div>
              <div className="pj-detail">Earn money by sharing <br />contents from blocksky.io.</div>
            </div>
            <div className="pj-items trade">
              <div className="pj-bg">&nbsp;</div>
              <div className="pj-thumbs">
                <img src={pj_trade} alt="" />
              </div>
              <div className="pj-topic">Trade Tokens</div>
              <div className="pj-detail">Trade Blocksky tokens.</div>
            </div>
            <div className="pj-items stake">
              <div className="pj-bg">&nbsp;</div>
              <div className="pj-thumbs">
                <img src={pj_stake} alt="" />
              </div>
              <div className="pj-topic">Staking</div>
              <div className="pj-detail">Earn dividends from <br />staking pool.</div>
            </div>
          </div>
          <div className="pj-social">
            <a href="https://twitter.com/blockskycrypto" target="_blank"><img src={twitter} alt="" /></a>
            <a href="https://t.me/+ja-BGSl1b6IzNzU1" target="_blank"><img src={telegram} alt="" /></a>
            <a href="https://www.facebook.com/blocksky.io" target="_blank"><img src={facebook} alt="" /></a>
          </div>
        </div>
      </section>
    )
};

export default Project;
