import React, { useState, useRef , useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "./Header";
import logo from '../../imgs/logo.png';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from "react-bootstrap";
import moment from 'moment-timezone';
import Spinner from 'react-bootstrap/Spinner';

const endpoint = process.env.REACT_APP_API_END_POINT;
const api_key = process.env.REACT_APP_API_KEY;

function ShareToEarnDashboard(){

    const [news , setNews] = useState([])
    let balance = 0.00
    const [usdPrice, setUsdPrice] = useState(0.00);
    const [link, setLink] = useState("")
    const [formattedDate, setFormattedDate] = useState(moment().tz('Asia/Bangkok').format('YYYY-MM-DD'));
    const [options, setOptions] = useState([]);
    const [current, setCurrent] = useState("")
    const [loading , setLoading] = useState(true)
    let earnToday = 0

    const generateDateSelection = () => {
        const currentDate = moment().tz('Asia/Bangkok');
        const thirtyDaysAgo = moment().subtract(30, 'days').tz('Asia/Bangkok');
    
        const generatedOptions = [];
        for (let date = currentDate; date >= thirtyDaysAgo; date.subtract(1, 'days')) {
            const formattedDate = date.format('YYYY-MM-DD');
            generatedOptions.push(
                <option key={formattedDate} value={formattedDate}>
                    {formattedDate}
                </option>
            );
        }
        setOptions(generatedOptions);
    };
    
    const handleDateChange = (event) => {
        const selectedTimestamp = event.target.value; // แปลงค่าเป็นตัวเลข
        setCurrent(selectedTimestamp)
        setFormattedDate(selectedTimestamp);
    };

    useEffect(() => {
        setCurrent(moment.tz('Asia/Bangkok').format('YYYY-MM-DD'))
        generateDateSelection();
        loadHistories();
    }, [formattedDate]); // ไม่มี dependency เพื่อให้เรียกใช้งานเพียงครั้งเดียวเมื่อ component ถูก mount


    const loadHistories = () => {
        const login_token = localStorage.getItem('login_token');
        const data = { login_token: login_token, date: formattedDate };
    
        axios.post(`${endpoint}/api/v1/news/shared`, data, {headers:{"X-API-KEY": api_key , "Content-Type": "application/json"}})
            .then(response => {
                setLoading(false)
                const news = response.data.news
                const calData = response.data.calData
                setNews(news); // ข่าว
                setUsdPrice(calData.priceUsd);
            })
            .catch(error => {
                console.error("Error fetching news:", error);
            });
    }
    

    const newsElements = news.map((item , index) => {
        const maxUsd = item.max_reward   

        let currentPoint = item.views_d1 / item.view_to_point
        if(formattedDate == item.filter_date[1]){
            currentPoint = item.views_d2 / item.view_to_point
        }
        
        let currentEarn = currentPoint / item.point_rate
        let percentageEarn = 0
        
        if(currentEarn > maxUsd){
            currentEarn = maxUsd;
        }
        
        percentageEarn = (currentEarn / maxUsd) * 100;
        earnToday = parseFloat(earnToday + currentEarn)
        if(earnToday > 0){
            earnToday = earnToday.toFixed(2)
        }else{
            earnToday = earnToday
        }
        
        balance = (earnToday / usdPrice)
        balance = balance.toFixed(2);

        percentageEarn = percentageEarn.toFixed(2)
        const originalDate = new Date(item.created_at);
        const year = originalDate.getFullYear();
        const month = String(originalDate.getMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getDate()).padStart(2, '0');
        const hours = String(originalDate.getHours()).padStart(2, '0');
        const minutes = String(originalDate.getMinutes()).padStart(2, '0');
        const seconds = String(originalDate.getSeconds()).padStart(2, '0');
        const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return  <div className="news-item" key={index}>
                    <Link to={item.news_link} target='_blank' className='read-news'>
                        <div className='cover-image' style={{ backgroundImage: "url('"+item.news_img_cover+"')" }}></div>
                    </Link>
                    <div className="news-body">
                        <h6>{item.news_title}</h6>
                        <div style={{fontSize: "12px" , width:percentageEarn+'%'}}>{percentageEarn}%</div>
                        <div className="progress" style={{height:'20px'}}>
                            <div className="progress-bar" role="progressbar"  style={{width:percentageEarn+'%'}} aria-valuenow={percentageEarn} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <span className='news-date'>Shared at : {formattedDateTime}</span>
                        <div className="news-action">
                        {item.news_link && (
                            <div className="form-inline gap-2">
                                <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(item.news_link)}`} target="_blank" rel="noopener noreferrer" className='share-to'>
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1024px-Facebook_Logo_%282019%29.png" alt="Facebook" />
                                </a>
                                <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(item.news_link)}`} target="_blank" rel="noopener noreferrer" className='share-to'>
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7fj1vqat9XLO4cFwOG1uFqLXYDcISiYil2w&usqp=CAU" alt="Twitter" />
                                </a>
                            </div>
                        )}
                        <button type='button' onClick={() => getLink(item._id , item.news_link)} className='get-link-btn'><i className="fas fa-link"></i> Get Link</button>
                        </div>
                    </div>
                </div>
    })

    const getLink = (id , link = null) => {
        setShow(true)
        setLink(link)
    }

    function shortenText(text, maxLength) {
        if (text.length <= maxLength) {
          return text;
        } else {
          return text.substring(0, maxLength) + "...";
        }
    }

    const copyToClipboard = () => {
        const linkInput = document.getElementById('link-input');
        linkInput.classList.remove('d-none'); 
        linkInput.select(); 
        document.execCommand('copy');
        linkInput.classList.add('d-none');
        alert('Link copied to clipboard!');
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="share-body">
            <Header page="history"></Header>
            <div className="container mt-4">
                <div className="news-wrappper justify-content-start">
                <div className="balance-card">
                    <h4>Expected to receive</h4>
                    <div className='balance-text'>
                        <span className='balance'>{balance}</span>
                        <div className='currency'>
                            <span className='currency-blocksky'>BLOCKSKY</span>
                            <span className='usdPrice'>≈ ${earnToday}</span>
                        </div>
                    </div>
                    <select className='card-selection' onChange={handleDateChange}>
                        {options}
                    </select>
                </div>

                {loading ? (
                            <div className='loading-data'>
                                <Spinner animation="grow" variant='light' role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                                <span className='text-white loading-text'>Loading...</span>
                            </div>
                        ) : null}
                    {newsElements}
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header className='share-modal-header' closeButton>
                    <Modal.Title>Link for sharing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='form-inline'> 
                            <input type="text" id='link-input' className='d-none' value={link} />
                            <span className='link-sharing'>
                                Link : {shortenText(link , 40)}
                                <button type='button' className='copy-btn' onClick={copyToClipboard}><i className="fas fa-copy"></i></button>
                            </span>
                        </div> 
                        <div className="form-inline gap-3 mt-3">
                            <span>Share to : </span>
                            <Link className='share-to'><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1024px-Facebook_Logo_%282019%29.png" /></Link>
                            <Link className='share-to'><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7fj1vqat9XLO4cFwOG1uFqLXYDcISiYil2w&usqp=CAU" alt="" /></Link>
                        </div>
                    </div>
                    <div className="form-inline mt-2">
                        <Button className='btn-sm ml-auto' variant="danger" onClick={handleClose}>Close</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ShareToEarnDashboard;